import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
import { isUndefined } from 'lodash';
import { StaticContext } from 'react-router';

import { setAfterLoginRoutes, SUBSCRIPTION_EXPIRY_ALLOWED_ROUTES } from 'config/config';
import { CustomRouteProps } from 'common/models/navigationModel';
import { UserPaths } from 'features/user/models/userModel';

import { FullPageSpinner } from './FullPageSpinner';
import { ContentMenu } from './ContentMenu';

export type RouteProps = RouteComponentProps<
    {
        [x: string]: string | undefined;
    },
    StaticContext,
    unknown
>;

export const CustomRoute = ({
    component: Component,
    isAuthenticated,
    isTokenChecked,
    redirect = '/sign-in',
    secured = false,
    path,
    activeOrganisation,
    isSubscriptionExpired,
    adminMenuLinks,
    contractManagerMenuLinks,
    menuLinks,
    exact,
    location,
    hideNavigation,
    SubNavigation,
    isUserAdmin,
    isUserTenant,
}: CustomRouteProps) => {
    if (activeOrganisation) {
        setAfterLoginRoutes(activeOrganisation.organisation.id);
    }
    const renderRouteContent = (routeProps: RouteProps) => {
        const allowedRoutes = [...SUBSCRIPTION_EXPIRY_ALLOWED_ROUTES];

        if (!Component) {
            return;
        }

        if (!isTokenChecked) {
            return <FullPageSpinner />;
        }

        if (isSubscriptionExpired) {
            if (isUserAdmin && isUserTenant) {
                allowedRoutes.push('/edit/subscriptions');
            } else if (isUserAdmin) {
                allowedRoutes.push('/edit');
            }

            const isRouteAllowed = allowedRoutes.some(
                (allowedRoute) => path && path.startsWith(allowedRoute),
            );

            if (isRouteAllowed) {
                return (
                    <>
                        {!hideNavigation && (
                            <ContentMenu
                                adminMenuLinks={adminMenuLinks}
                                contractManagerMenuLinks={contractManagerMenuLinks}
                                menuLinks={menuLinks}
                            />
                        )}
                        {SubNavigation && <SubNavigation {...routeProps} />}
                        <Component {...routeProps} />
                    </>
                );
            }
            let redirectPath = '/user/update-user-data';
            if (isUserAdmin) {
                redirectPath = `/edit`;
            }
            return (
                <Redirect
                    to={{
                        pathname: redirectPath,
                        state: { referrer: location },
                    }}
                />
            );
        }

        if (isAuthenticated === secured) {
            return isAuthenticated && isUndefined(activeOrganisation) ? (
                <FullPageSpinner />
            ) : (
                <>
                    {!hideNavigation && (
                        <ContentMenu
                            adminMenuLinks={adminMenuLinks}
                            contractManagerMenuLinks={contractManagerMenuLinks}
                            menuLinks={menuLinks}
                        />
                    )}
                    {SubNavigation && <SubNavigation {...routeProps} />}
                    <Component {...routeProps} />
                </>
            );
        }

        if (path == UserPaths.VerifyNewAccount || path == UserPaths.VerifyNewExecutive) {
            return <Component {...routeProps} />;
        }

        return (
            <Redirect
                to={{
                    pathname: redirect,
                    state: { state: 'Access denied', referrer: location },
                }}
            />
        );
    };

    return <Route path={path} exact={exact} render={renderRouteContent} />;
};
