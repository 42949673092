import { useDispatch, useSelector } from 'react-redux';
import { MenuProps, Tabs } from 'antd';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import { redirectToUrl, setPreviousLink } from 'common/actions/navigationAction';
import {
    isAdmin as getIsAdmin,
    getOrganisationCurrentSubscription,
} from 'features/user/selectors/userSelectors';
import { isContractManager as getIsContractManager } from 'features/contract/selectors/contractSelectors';
import { ContentMenuLink, MenuLinks } from 'common/models/navigationModel';
import { breakpoints, colors } from 'theme/variables';
import { useEffect } from 'react';
import { getPreviousPath } from 'features/check/selectors/checkSelectors';
import { getPreviousPath as getUploadPreviousPath } from 'features/upload/selectors/uploadSelectors';
import { SUBSCRIPTION_EXPIRY_ALLOWED_TABS } from 'config/config';
const ContentMenuWrapper = styled.div<{ small?: boolean }>(
    ({ small }) => `
    border-radius: 4px 4px 0 0;
    width: 100%;
    background-color: ${small ? colors.lightGray : colors.primaryWhite};

    .ant-tabs {

        > .ant-tabs-nav {
            margin-bottom: 0;
            height: ${small ? '32px' : 'auto'};

            .ant-tabs-nav-wrap {
                justify-content: flex-start;
                padding-left: 32px;

                ${breakpoints.desktop} {
                    justify-content: center;
                }

                .ant-tabs-nav-list {
                    padding-right: 32px;
                }

                .ant-tabs-tab {
                    font-size: ${small ? '12px' : '14px'};
                }
            }
        }
    }
`,
);

type Props = MenuProps &
    MenuLinks & {
        small?: boolean;
    };

export const ContentMenu = ({
    adminMenuLinks,
    contractManagerMenuLinks,
    menuLinks,
    small,
}: Props) => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const isContractManager = useSelector(getIsContractManager);
    const isAdmin = useSelector(getIsAdmin);
    const previousPathname = useSelector(getPreviousPath);
    const submitPreviousPath = useSelector(getUploadPreviousPath);
    const subscription = useSelector(getOrganisationCurrentSubscription);

    const isExpired = subscription?.status === 'EXPIRED';

    let links: ContentMenuLink[] | undefined = [];
    if (isAdmin && adminMenuLinks) {
        links = isExpired
            ? adminMenuLinks?.filter((link) =>
                  SUBSCRIPTION_EXPIRY_ALLOWED_TABS.includes(link.label),
              )
            : adminMenuLinks;
    } else if (isContractManager) {
        links = contractManagerMenuLinks;
    } else {
        links = menuLinks;
    }

    const getDefaultActiveKey = () => {
        let activeKey = '';

        if (!links || links.length === 0) {
            return activeKey;
        }

        if (previousPathname && pathname.startsWith('/check/evidences/edit/project/')) {
            return previousPathname;
        }

        if (submitPreviousPath && pathname.startsWith('/upload/organisation/')) {
            return submitPreviousPath;
        }

        for (const link of links) {
            if (pathname.startsWith(link.key) || link.additionalActiveKeys?.includes(pathname)) {
                activeKey = link.key;
                break;
            }
        }

        return activeKey;
    };

    const handleOnChange = (key: string) => {
        dispatch(setPreviousLink(key));
        dispatch(redirectToUrl(key));
    };

    useEffect(() => {
        dispatch(setPreviousLink(pathname));
    }, [pathname]);

    return (
        <ContentMenuWrapper small={small}>
            <Tabs
                onChange={handleOnChange}
                defaultActiveKey={getDefaultActiveKey()}
                animated={false}
            >
                {links?.map(({ key, label, Icon }) => (
                    <Tabs.TabPane
                        key={key}
                        tab={
                            Icon ? (
                                <>
                                    {Icon}
                                    {label}
                                </>
                            ) : (
                                label
                            )
                        }
                    />
                ))}
            </Tabs>
        </ContentMenuWrapper>
    );
};
