import { UploadFile } from 'antd/lib/upload/interface';

import { OrganisationIdentity, NoteFileData } from 'features/upload/models/uploadModels';
import { InviteByEmailWithRole } from 'features/invite/models/inviteModel';
import { IdValueNameObject } from 'common/models/defaultsModel';

import {
    BenefitQuestionnaires,
    BenefitQuestionnairesEditability,
    SubmittableQuestionnaires,
} from './questionnaireModels';

export enum EditBenefitPaths {
    Details = '/benefit-details',
    BenefitForm = '/benefit-form',
    Notes = '/benefit-notes',
    Filter = '/filter',
}

export enum FileStatus {
    UNTESTED = 'UNT',
    TESTED = 'TST',
    QUARANTINED = 'QNT',
}

export enum Priority {
    CRITICAL = 'CRITICAL',
    HIGH = 'HIGH',
    MEDIUM = 'MEDIUM',
    LOW = 'LOW',
}

export const prioritiesAsSelectOptions: IdValueNameObject[] = [
    { name: 'Critical' },
    { name: 'High' },
    { name: 'Medium' },
    { name: 'Low' },
];

export enum BenefitStatus {
    PARTIALLY_DELIVERED = 'Partially Delivered',
    SUBSTITUTED = 'Substituted',
    DELAYED = 'Delayed',
    ABANDONED = 'Abandoned',
    OVERDUE = 'Overdue',
    DELIVERED = 'Delivered',
    NOT_DELIVERED = 'Not Delivered',
    IN_PROGRESS = 'In Progress',
    NOT_STARTED = 'Not Started',
}

export enum BenefitRoleName {
    Assessor = 'Assessor',
    DeliveryPartner = 'Delivery partner',
    Supplier = 'Supplier',
}

export enum BenefitRole {
    ASSESSOR = 'ASSESSOR',
    SUPPLIER = 'SUPPLIER',
    DELIVERY_PARTNER = 'DELIVERY_PARTNER',
}

export const mapBenefitRoleShortcuts = new Map([
    ['ASSESSOR', BenefitRoleName.Assessor],
    ['DELIVERY_PARTNER', BenefitRoleName.DeliveryPartner],
    ['SUPPLIER', BenefitRoleName.Supplier],
]);

export interface BenefitRoleData {
    id: number;
    role: BenefitRole;
    benefit: number;
    user: null;
    organisation: number;
    userFirstName?: string;
    userLastName?: string;
}

export interface BenefitOrganisationRole {
    user: number;
    userFirstName?: string;
    userLastName?: string;
    name: string;
    contract?: number;
    id: number;
    organisation?: number;
    email?: string;
    role: string;
    benefit: number;
}

export interface AssignEmployeeRequest extends BenefitPathParams, OrganisationIdentity {
    role: string;
    user: number;
    roleId?: number;
}

export interface Benefit extends BenefitRequest, SubmittableQuestionnaires {
    id: number;
    sharedBenefit: number;
    label: string;
    priority?: string;
    referenceNumber?: string;
    organisation: number;
    categoryName: string;
    locationName: string;
    location: number;
    type: string;
    statusExplanation: string;
    evidenceFileTemplate?: string;
    scoreFileTemplate?: string;
    evidenceDescriptionTemplate?: string;
    scoreDescriptionTemplate?: string;
    deliveredEmissionSavings?: string;
    deliveredPoints?: string;
    deliveredValue?: string;
    valueHint?: number;
    pointsHint?: number;
    emissionSavingsHint?: number;
    benefitTemplates: BenefitTemplate[];
    contractTitle: string;
    selfAssessmentAllowed?: boolean;
    priorities: BenefitPriority[];
    reportedOn?: string;
    submitters?: string;
}

export interface BenefitPriority {
    id: number;
    priority: {
        id: number;
        name: string;
    };
    priorityName: string;
    value: number;
    name: string;
}

export interface FetchedBenefitPriority {
    id: number;
    priority: number;
    value: number;
    priorityName: string;
    benefit: number;
}

export interface BenefitPriorityDashboardItem {
    id: number;
    name: string;
    deliveredPriorityValue: number;
    benefitIds: number[];
}

export interface BenefitTemplateWithRag {
    id: number;
    outcome: string;
    categoryName: string;
    deliveredBenefitCount: number;
    value: number;
    points: number;
    emissionSavings: number;
}

export interface BenefitRequest extends BenefitQuestionnaires {
    category: number;
    contract: number;
    description: string;
    finalDeliveryDate: string;
    outcome: string;
    points: string;
    quantity: number;
    startDate: string;
    status: string;
    value: string;
    deliveryDates: DeliveryDate[];
    method: string;
    statusExplanation: string;
    assessors: Assessors[];
    suppliers: Suppliers[];
    partners: Partners[];
    individual_suppliers: IndividualExecutives[];
    individual_partners: IndividualExecutives[];
    invite_pending_individual_users: InvitePendingIndividualExecutivesRequest[];
    new_executive_users: InviteByEmailWithRole[];
    priorities: BenefitPriority[];
    emissionSavings: string;
}

export interface Assessors {
    user: number;
}

export interface Suppliers {
    organisation: number;
}

export interface Partners {
    organisation: number;
}

export interface IndividualExecutives {
    user: number;
    organisation: number;
}

export interface InvitePendingIndividualExecutivesRequest {
    userInvitation: number;
    role?: BenefitRole;
    benefit?: number;
    contract?: number;
}

export interface BenefitCategory {
    archived: boolean;
    id: number;
    name: string;
    organisation: number;
}

export interface BenefitPathParams {
    benefitId: number;
    contractId: number;
}

export interface BenefitsRolesRequest {
    benefitIds: number[];
    contractId: number;
    organisationId?: number;
}

export interface BenefitPrioritiesParams extends BenefitPathParams {
    priorityId: number;
}

export interface FormDeliveryDate {
    id?: number;
    title?: string;
    date?: Date;
}

export interface DeliveryDate {
    id: number;
    benefit: number;
    date: string;
    title: string;
    needsUserAction: boolean;
    isStart?: boolean;
    isFinal?: boolean;
}

export interface EditBenefitRequest {
    benefitId: number;
    sharedBenefit?: number;
    sharedBenefitForUrl?: number;
    category?: number;
    contract?: number;
    description?: string;
    finalDeliveryDate?: string;
    outcome?: string;
    points?: number;
    quantity?: number;
    startDate?: string;
    status?: string;
    value?: number;
    deliveryDates?: Partial<DeliveryDate>[];
    method?: string;
    statusExplanation?: string;
    deliveredEmissionSavings?: number;
    deliveredPoints?: number;
    deliveredValue?: number;
    emissionSavings?: number;
    location?: number;
    label?: string;
    reportedOn?: string;
}

export interface ContractBenefit {
    id: number;
    category: number;
    contract: number;
    description: string;
    finalDeliveryDate: string;
    outcome: string;
    points: number;
    quantity: number;
    sharedBenefit: number;
    startDate: string;
    type: string;
    value: number;
    status: string;
    categoryName: string;
    referenceNumber: string | null;
    textEvidenceAssessorTemplate: string | null;
    textEvidenceSupplierTemplate: string | null;
    textEvidencePartnerTemplate: string | null;
    textScoreAssessorTemplate: string | null;
    textScoreSupplierTemplate: string | null;
}

export interface BenefitTemplate extends BenefitQuestionnaires, BenefitQuestionnairesEditability {
    id: number;
    createdAt?: string;
    archived: boolean;
    category: number;
    organisation: number;
    outcome: string;
    description?: string;
    outcomeInventory: number;
    priority?: Priority;
    valueHint?: string;
    pointsHint?: string;
    emissionSavingsHint?: string;
    evidenceAssessorTemplate?: string;
    evidenceAssessorTemplateName?: string;
    evidenceSupplierTemplate?: string;
    evidenceSupplierTemplateName?: string;
    evidencePartnerTemplate?: string;
    evidencePartnerTemplateName?: string;
    scoreAssessorTemplate?: string;
    scoreAssessorTemplateName?: string;
    scoreSupplierTemplate?: string;
    scoreSupplierTemplateName?: string;
}

export interface BenefitTemplateFormValues extends BenefitQuestionnaires {
    outcome: string;
    description?: string;
    category: number;
    pointsHint?: number;
    valueHint?: number;
    emissionSavingsHint?: number;
    outcomeInventory: number;
    priority?: Priority;
}

export interface CreateBenefitTemplateRequest extends BenefitQuestionnaires {
    category: number;
    outcome: string;
    description?: string;
    outcomeInventory: number;
    priority?: Priority;
    valueHint?: string;
    pointsHint?: string;
    emissionSavingsHint?: string;
}

export interface EditBenefitTemplateRequest extends CreateBenefitTemplateRequest {
    id: number;
}

export interface SelectBenefitTemplateRequest {
    contract: number;
    finalDeliveryDate: string;
    startDate: string;
    id: number;
    quantity: number;
    value: number;
    status: string;
    deliveryDates: DeliveryDate[];
    points: number;
    method: string;
    assessors: Assessors[];
    suppliers: Suppliers[];
    partners: Partners[];
    individual_suppliers: IndividualExecutives[];
    individual_partners: IndividualExecutives[];
    invite_pending_individual_users: InvitePendingIndividualExecutivesRequest[];
    new_executive_users: InviteByEmailWithRole[];
    emissionSavings: number;
    reportedOn?: string;
}

export interface FilterBenefitRequest {
    path: string;
    assessor: number;
    assessorScore: string;
    supplierScore: string;
    contractCategory: number;
    location: number;
    contractType: string;
    minStartDate: string;
    maxStartDate: string;
    minEndDate: string;
    maxEndDate: string;
    contractTitle: string;
    contractReferenceNumber: string;
    supplierName: string;
    deliveryPartnerName: string;
    deliveryPartner: number;
    deliveryPartnerExecutive: number;
    outcome: string;
    label: string;
    published: boolean;
    status: string;
    contractManager: number;
    contractDepartment: number;
    contractValueMin: number;
    contractValueMax: number;
    pointsMin: number;
    pointsMax: number;
    supplier: number;
    supplierExecutive: number;
    method: string;
    emissionSavingsMin: number;
    emissionSavingsMax: number;
    benefitType: string;
    tenant: number;
    reportedOn: string;
}

export interface FilterContractBenefitsRequest extends FilterBenefitRequest {
    contractId: number;
}

export interface Path {
    path: string;
}

export interface FilteredBenefits extends Path {
    benefits: Benefit[];
}

export interface FilteredContractBenefits extends Path {
    filteredContractId?: number;
    contractBenefits: ContractBenefit[];
}

export const benefitStatuses = [
    { name: 'Not Started' },
    { name: 'In Progress' },
    { name: 'Delayed' },
    { name: 'Abandoned' },
    { name: 'Overdue' },
    { name: 'Delivered' },
    { name: 'Partially Delivered' },
    { name: 'Substituted' },
    { name: 'Not Delivered' },
];

export interface BenefitDataRequest {
    contractId: number;
    benefitId: number;
    organisationId?: number;
}

export interface AddBenefitRoleRequest {
    contractId: number;
    benefitId: number;
    role: BenefitRole;
    organisationId: number;
    userId?: number;
}

export interface AddBenefitPriorityRequest {
    contractId: number;
    benefitId: number;
    priority: {
        priority: number;
        value: number;
        name: string;
    };
}

export interface CreateBenefitTemplateFilesRequest {
    evidenceAssessorTemplate?: UploadFile | null;
    evidenceSupplierTemplate?: UploadFile | null;
    evidencePartnerTemplate?: UploadFile | null;
    scoreAssessorTemplate?: UploadFile | null;
    scoreSupplierTemplate?: UploadFile | null;
}

export interface EditBenefitTemplateFilesRequest extends CreateBenefitTemplateFilesRequest {
    id: number;
}

export type CloneBenefitTemplateRequest = {
    id: number;
    form: CreateBenefitTemplateRequest;
    files: CreateBenefitTemplateFilesRequest;
};

export type MigrateBenefitsRequest = {
    sourceTemplateId?: number;
    targetTemplateId?: number;
};

export interface EditBenefitPriorityRequest {
    contractId: number;
    benefitId: number;
    priorityId: number;
    value?: number;
}

export interface DeleteBenefitRoleRequest extends BenefitDataRequest {
    roleId: number;
}

export interface ChangeBenefitStatusRequest {
    status: string;
    contractId: number;
    benefitId: number;
    sharedBenefitId: number;
    organisationId: number;
}

export enum AssignActions {
    Assign = 'assign',
    Revoke = 'revoke',
}

export interface ToggleMultipleEmployeesRoleRequest {
    userIds: number[];
    benefitIds: number[];
    action: AssignActions;
    organisationId?: number;
}

export interface ToggleMultipleInvitePendingExecutiveUsersRoleRequest {
    userInvitationIds: number[];
    benefitIds: number[];
    action: AssignActions;
    organisationId: number;
}

export interface ArchiveBenefitTemplateRequest {
    id: number;
    archived: boolean;
}

export interface BenefitNoteData {
    contract: number;
    createdAt: string;
    updatedAt: string;
    id: number;
    organisation: number; // tidy up check if really needed
    authoredBy: string;
    note: string;
    files: NoteFileData[];
}

export interface CreateBenefitNoteRequest {
    contract: number;
    benefit: number;
    organisation: number;
    file?: File[];
    note?: string;
}

export interface DeleteBenefitNoteRequest {
    contract: number;
    benefit: number;
    id: number;
}

export interface EditBenefitNoteRequest {
    contract: number;
    benefit: number;
    id: number;
    note: string;
}

export interface DeleteBenefitNoteFileRequest {
    contract: number;
    benefit: number;
    id: number;
}

export interface AddBenefitNoteFileRequest {
    contract: number;
    benefit: number;
    file: UploadFile;
    note: number;
}

export interface GetBenefitNoteRequest {
    contract: number;
    benefit: number;
}
